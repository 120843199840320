@import '/styles/colors';

body {
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
  font-family: 'Open Sans', Sans-serif;
  color: $x-dark-grey;
}

@media (max-width: 639px) {
  body {
    font-size: 0.9em;
  }
}

.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  padding: 3.5rem 0 4.5rem;
  >:first-child {
    margin-top: 0;
  }
}

h1, h2, h3, h4 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.3;
  font-weight: 700;
}

h1 {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  color: $primary-color;
}

h2 {
  font-size: 1.6em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
}

h3 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  color: $dark-accent;
}

h4 {
  font-size: 1.25em;
  font-weight: 800;
  color: $dark-accent;
  font-family: 'Roboto Condensed';
  color: $dark-accent;
  margin-top: 1.3em;
  margin-bottom: 0.2em;
}

@media (max-width: 639px) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
}

p {
  margin: 0.5em 0 1em;
}

a {
  text-decoration: none;
  color: $light-accent;
  font-weight: 600;
  transition: all 0.1s ease-in-out;
  &:hover {
   text-decoration: underline;
  }
}

.link {
  color: $light-accent;
  font-weight: 600;
  transition: all 0.1s ease-in-out;
  &:hover {
   text-decoration: underline;
  }
}

ul {
  padding-left: 20px;
  list-style-type: square;
}

.btn {
  display: block;
  margin: 0.5rem 0 1rem;
  padding: 0.6rem 2rem;
  background-color: $dark-accent;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 0.95em;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2;
  cursor: pointer;
  min-width: 200px;
  width: auto;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: $x-dark-accent;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: $medium-grey;
  }
}

label {
  display: block;
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  color: $dark-grey;
  margin: 0.2rem 0;
}

input, textarea {
  padding: 0.6rem;
  width: 100%;
  margin-bottom: 1.2rem;
  border: 1px solid $medium-grey;
  background-color: $light-grey;
  box-sizing : border-box;
  &.input-error {
    border: 1px solid red;
    background-color: #fff6f6;
  }
  &:focus {
    outline: 1px ridge $medium-grey;
  }
}

.form-errors {
  color: red;
  margin-bottom: 1.5rem;
  font-size: 0.9em;
  font-weight: 700;
  background-color: #fff6f6;
  padding: 0.5rem 0.8rem;
}

.form-success {
  color: green;
  margin-bottom: 1.5rem;
  font-size: 0.9em;
  font-weight: 700;
  background-color: #ddf9de;
  padding: 0.5rem 0.8rem;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 0.8em;
}

.text-lg {
  font-size: 1.15em;
  font-weight: 600;
}

.text-xl {
  font-size: 2.2em;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
}

.fas {
  font-family: 'FontAwesome';
  font-style: normal;
}

.fa-facebook-square {
  font-family: FontAwesome;
  font-style: normal;
  font-size: 1.6em;
  color: $dark-accent;
}

.eyebrow {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  color: $md-grey;
  margin: 0.2rem 0;
  font-family: 'Roboto Condensed';
}

.page-header {
  text-align: center;
  background-image: url('./images/banners/hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 85%;
  display: flex;
  align-items: center;
  height: 380px;
  &.events-header {
    background-image: url('./images/banners/events.jpg');
    background-position: center 45%;
  }
  &.about-header {
    background-image: url('./images/banners/about.jpg');
    background-position: center 40%;
  }
  &.projects-header {
    background-image: url('./images/banners/projects.jpg');
    background-position: center 40%;
  }
  &.getInvolved-header {
    background-image: url('./images/banners/get-involved.jpg');
    background-position: center 45%;
  }
  &.contact-header {
    background-image: url('./images/banners/contact.jpg');
    background-position: center 70%;
  }
}

@media (max-width: 639px) {
  .page-header {
    height: 250px;
  }
}

.overlay {
  background: linear-gradient(to right, transparent, #00000030, #00000040, #00000030, transparent);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: 1px 1px 2px $text-shadow;
  padding-bottom: 3.5rem;
  box-sizing: border-box;
  h1 {
    color: #fff;
    padding: 0.1em 0;
    border-bottom: 3px solid;
    margin-bottom: 0;
  }
  h2 {
    color: $light-grey;
    margin-top: 0.8em;
    margin-bottom: 0;
  }
}

@media (max-width: 639px) {
  .overlay {
    background: #00000050;

  }
}

.hero {
  background-image: url('./images/banners/hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 90%;
  height: 550px;
  .overlay {
    background: #00000045;
  }
}

@media (max-width: 639px) {
  .hero {
    height: 350px;
  }
}

.section-break {
  width: 100%;
  height: 1.3rem;
}

.small-section-break {
  width: 100%;
  height: 0.5rem;
}

@media (max-width: 767px) {
  .small-section-break {
    height: 0.1rem;
  }
}

.text-box {
  background-color: $light-grey;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 1.8rem 2rem;
  margin: 1rem 0 3rem;
  box-sizing: border-box;
  border-radius: 4px;
  >:first-child {
    margin-top: 0;
  }
  >:last-child {
    margin-bottom: 0;
  }
}

.project-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  > div {
    width: 50%;
    display: flex;
    margin: 0 auto;
    &.full-width {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .project-wrapper {
    > div {
      width: 100%;
    }
  }
}

.project-box {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin: 2rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
  >:first-child {
    margin-top: 0;
  }
  >:last-child {
    margin-bottom: 0;
  }
  .project-image {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .project-text {
    background-color: $light-grey;
    padding: 1.8rem 2rem;
    text-decoration: none;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  a {
    color: inherit;
    font-weight: inherit;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    .project-image {
      -ms-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
      filter: brightness(1.1);
    }
    .project-text {
      -ms-transform: translateY(-0.4rem);
      -moz-transform: translateY(-0.4rem);
      -webkit-transform: translateY(-0.4rem);
      -o-transform: translateY(-0.4rem);
      transform: translateY(-0.4rem);
    }
  }
}

.photo-box-wrapper {
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
  .overlay {
    height: 100%;
    border-radius: 4px;
    padding: 1.4rem 1.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000030;
  }
  &.photo-box-wrapper-dark {
    .overlay {
      background: #00000060;
    } 
  }
  &:hover {
    .photo-box {
      -ms-transform: scale(1.05);
      -moz-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
      filter: brightness(0.8);
    }
    .photo-box-caption {
      -ms-transform: translateY(-0.4rem);
      -moz-transform: translateY(-0.4rem);
      -webkit-transform: translateY(-0.4rem);
      -o-transform: translateY(-0.4rem);
      transform: translateY(-0.4rem);
    }
  }
}

.photo-box {
  background-image: url('./images/photo-boxes/treeplanting-project.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  height: 350px;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  &.treeplanting {
    background-image: url('./images/photo-boxes/treeplanting-project.jpg');
    background-position: center 0%;
  }
  &.gathering {
    background-image: url('./images/photo-boxes/reunion.jpg');
  }
  &.events {
    background-image: url('./images/photo-boxes/treeplanting.jpg');
  }
  &.projects {
    background-image: url('./images/photo-boxes/projects.jpg');
    background-position: center 40%;
  }
}

.photo-box-title {
  text-transform: uppercase;
  text-shadow: 1px 1px 2px $text-shadow;
  color: #fff;
  font-size: 2.5em;
  line-height: 1.3em;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  width: 100%;
}
.photo-box-title {
  hr {
    border: none;
    border-top: 3px solid;
    color: #fff;
    height: 0;
    width: 10%;
    margin: 0.8rem 0 0;
    text-shadow: none;
  }
}
.photo-box-subtitle {
  text-transform: uppercase;
  text-shadow: 1px 1px 2px $text-shadow;
  color: #fff;
  font-size: 1.7em;
  line-height: 1.3em;
  font-family: 'Roboto Condensed';
  font-weight: 700;
  width: 100%;
}
.photo-box-text {
  color: #fff;
  font-weight: 600;
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 1px 1px 2px $text-shadow;
  width: 100%;
}
.photo-box-caption {
  background-color: #000;
  color: #fff;
  padding: 1.2rem 1.2rem 1.6rem;
  font-size: 0.95em;
  font-weight: 600;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: -0.4rem;
  left: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.cols {
  display: flex;
  justify-content: space-between;
  > * {
    width: 50%;
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .cols {
    display: block;
    width: 100%;
    > * {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.center-link {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0.5rem 0;
  cursor: pointer;
  color: $light-accent;
  font-size: 1em;
  &.down-arrows {
    &:before, &:after {
      content: '\f107';
      font-family: 'FontAwesome';
      font-style: normal;
      margin-right: 0.5rem;
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
    }
    &:after {
      margin-left: 0.5rem;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

.back-link {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 4rem;
  cursor: pointer;
  color: $light-accent;
  font-size: 0.9em;
  position: absolute;
  top: 0;
  &:before {
    content: '\f100';
    font-family: 'FontAwesome';
    font-style: normal;
    margin-right: 0.5rem;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}


/* --- OVERRIDES --- */
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 0.5em;
}

.mb-0 {
  margin-bottom: 0;
}


/* --- ANIMATIONS --- */
.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in-out;
}





