@import '../../styles/colors';

.committee {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	margin-left: -1rem;
	margin-right: -1rem;
}
.committee-member {
	text-align: center;
	padding: 1rem;
	margin: 0.4rem 0;
	box-sizing: border-box;
	min-width: 25%;
	width: auto;
}
.com-photo-wrapper {
	border-radius: 50%;
	width: 180px;
	height: 180px;
	background-color: $lm-grey;
	overflow: hidden;
	margin: 0 auto;
	img {
		width: 100%;
	}
}

@media (max-width: 639px) {
	.committee-member {
		padding: 0.8rem;
	}
	.com-photo-wrapper {
		width: 150px;
		height: 150px;
	}
}