@import '../../styles/colors';

.h3-w-line {
  display: flex;
  align-items: center;
  margin: 5rem 0 0;
  height: 1.5em;
  padding: 0.8rem 0;
  position: relative;
  .h3-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
  h3 {
    padding: 0 1rem;
    margin: 1.5px 0 0 0;
    background-color: #fff;
    width: auto;
    display: inline-block;
  }
  .h3-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 3px dotted $lm-grey;
    width: 100%;
    height: 1em;
  }
}