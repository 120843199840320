$primary-color: #2f5230;

$light-grey: #fcfcfc;
$lm-grey: #eee;
$medium-grey: #ccc;
$md-grey: #888;
$dark-grey: #666;
$x-dark-grey: #444;

$light-accent: #4ba34e;
$dark-accent: #2a2b2a;
$x-dark-accent: #111;

$box-shadow: rgba(0, 0, 0, 0.2);
$text-shadow: rgba(0, 0, 0, 0.4);