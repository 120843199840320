@import '../../styles/colors';

.footer {
	background-color: $dark-accent;
	color: #fff;
	padding: 1rem 0;
	.content {
		padding: 1rem 0;
	}

	.footer-left {
		width: 100%;
		margin-bottom: 0.5rem;
		.logo-wrapper {
			display: inline-block;
		}

		.logo-name {
			color: #fff;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.footer-col-nav {
		margin: 2rem 0;
		display: flex;
		flex-flow: wrap;
		a {
			color: #fff;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 1em;
			font-weight: 700;
			font-family: 'Roboto Condensed';
			white-space: nowrap;
			padding: 0 0.5rem;
		}
		a:first-child {
			padding-left: 0;
		}
		a:last-child {
			padding-right: 0;
		}
	}

	.footer-right {
		text-align: right;
		margin-top: 0.5rem;
	}

	.social {
		line-height: 1;
		margin-bottom: 4rem;
		span {
			text-transform: uppercase;
			font-size: 1em;
			font-weight: 700;
			font-family: 'Roboto Condensed';
			white-space: nowrap;
			vertical-align: middle;
			display: block;
		}
		a {
			color: #fff;
			vertical-align: middle;
			margin: 0.8rem 0;
			display: block;
			&:hover {
				color: $medium-grey;
			}
		}
		.fa-facebook-square  {
			font-size: 2.2em;
			color: #fff;
		}
	}

	@media (max-width: 767px) {
		.footer-wrapper {
			display: block;
			text-align: center;
			.logo-wrapper {
				display: block;
			}
			.logo {
				margin-right: 0;
			}
			.logo-name {
				margin-top: 1.2rem;
			}
			.social {
				text-align: center;
			}
		}
		.footer-col-nav {
			justify-content: center;
		}
		.copyright, .credit {
			text-align: center;
		}
	}

	@media (max-width: 639px) {
		.logo-full {
			display: block;
		}
	}
}


.copyright {
	margin-bottom: 0;
}

.credit {
	margin-bottom: 0;
	font-family: 'PT Sans Narrow';
	font-size: 0.82em;
	color: $md-grey;
	a {
		color: inherit;
	}
}
