@import '../../styles/colors';


.gal-thumbnails {
	width: 100%;
	display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin: 0.5rem -0.8rem 0;
    margin: 0 auto;
}
.gal-thumbnail-wrapper {
	width: 33%;
	height: 200px;
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	margin: 0.8rem 0;
	box-sizing: border-box;
	cursor: pointer;
	overflow: hidden;
}
.gal-thumbnail {
	width: 100%;
}

@media (max-width: 1023px) {
	.gal-thumbnail-wrapper {
		height: 180px;
	}
}

@media (max-width: 899px) {
	.gal-thumbnail-wrapper {
		height: 150px;
	}
}

@media (max-width: 767px) {
	.gal-thumbnail-wrapper {
		width: 50%;
		height: 200px;
	}
}

@media (max-width: 399px) {
	.gal-thumbnail-wrapper {
		width: 100%;
	}
}

.gallery-full {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000ee;
	z-index: 3;
	overflow: hidden;;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.gal-photo-wrapper {
		position: relative;
		text-align: center;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}
	.gal-image-wrapper {
		position: absolute;
		top: 0;
    	bottom: 0;
		left: 0;
    	right: 0;
    	height: 100vh;
    	display: flex;
    	align-items: center;
    	justify-content: center;
	}
	.gal-image-next-wrapper {
		position: absolute;
		top: 0;
    	bottom: 0;
		left: 0;
    	right: 0;
    	height: 100vh;
    	display: flex;
    	align-items: center;
    	justify-content: center;
	}
	.gal-image {
		width: 60%;
	}
	.fa-times {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 4;
		padding: 1rem 2rem;
		cursor: pointer;
		font-size: 2em;
		color: $lm-grey;
		cursor: pointer;
		transition: color 0.2s ease-in-out;
		&:hover {
			color: #fff;
		}
	}
	.gal-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		width: 20%;
		box-sizing: border-box;
		font-size: 3em;
		color: $dark-grey;
		.fas {
			padding: 2rem;
			cursor: pointer;
			transition: color 0.2s ease-in-out;
			&:hover {
				color: $lm-grey;
			}
		}
	}
	.gal-left {
		left: 0;
		justify-content: flex-end;
	}
	.gal-right {
		right: 0;
		justify-content: flex-start;

	}
}

@media (max-width: 767px) {
  	.gallery-full {
		.gal-image {
			width: 80%;
		}
		.gal-arrow {
			width: 10%;
			font-size: 2em;
			justify-content: center;
			.fas {
				padding: 0;
			}
		}
	}
}