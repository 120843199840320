@import '../../styles/colors';

.contact {
	.contact-info {
		width: 35%;
		min-width: auto;
  	.fa-facebook-square {
	  	margin-right: 0.5rem;
	  	vertical-align: middle;
	}
	.connect {
	  	vertical-align: middle;
	}
	}
}

@media (max-width: 767px) {
	.contact {
		.contact-info {
			width: 100%;
		}
	}
}