@import '../../styles/colors';

.header-space {
	height: 105px;
}

.header {
	color: $dark-accent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    max-width: 100%;
    .content {
    	padding: 0;
    }
}

.header-wrapper {
	display: flex;
	align-items: center;
}

.logo-wrapper {
	padding: 0.5rem 0;
}

.logo-text-wrapper {
	display: flex;
	align-items: center;
	&:hover {
		text-decoration: none;
	}
}

.logo {
	margin: 0 1rem 0 0;
	width: 95px;
	vertical-align: middle;
}

.logo-name {
	font-size: 1.2em;
	line-height: 1.2em;
	font-family: 'PT Sans Narrow';
	font-weight: 400;
	color: $dark-accent;
	.logo-acc {
		display: block;
		font-size: 1.2em;
		font-weight: 700;
	}
	.logo-full {
		margin-top: 0.4rem;
	}
}

@media (max-width: 639px) {
	.logo {
		width: 60px;
		margin-right: 0.6rem;
	}
	.logo-name {
		.logo-full {
			display: none;
		}
	}
}

.header-top-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.mobile-nav {
	display: none;

	.mobile-nav-icon {
		padding: 0.5rem;
		cursor: pointer;
		i {
			font-size: 1.8em;
		}
	}
}

.mobile-nav-items {
	display: none;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	background-color: #fff;
	border-top: 1px solid $medium-grey;
	min-height: 100vh;
	//opacity: 0;
	//transform: translate(-100%);

	list-style-type: none;
	font-size: 1.2em;
	font-family: 'Roboto Condensed';
	text-transform: uppercase;
	padding: 1.5rem 2rem;
	> li {
		border-bottom: 1px solid $lm-grey;
		position: relative;
		a {
			color: $dark-accent;
			padding: 1rem 0;
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			text-decoration: none;
		}
	}
	.sub-nav {
		list-style-type: none;
		margin: 0.5rem 0 1.5rem;
		padding: 0;
		text-transform: capitalize;
		a {
			padding: 0 1rem;
			color: $dark-accent;
		}
	}
	.sub-nav-arrow {
		width: auto;
		margin: 0;
		padding: 0 0.6rem;
		vertical-align: middle;
		i {
			font-size: 1.2em;
			line-height: 1;
		}
	}			
}

.nav {
	display: flex;
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 1.2em;
	line-height: 1.2;
	font-weight: 700;
	font-family: 'Roboto Condensed';
	list-style: none;
	align-self: flex-end;

	> li {
		position: relative;
		padding: 1rem 0.1rem;	
		cursor: pointer;
		&:hover {
			> .nav-item {
				border: 2px solid;
				span {
					border: none;
				}
			}
		}
	}

	.nav-item {
		text-transform: uppercase;
		text-decoration: none;
		white-space: nowrap;
		border: 2px solid transparent;
		border-radius: 4px;
		padding: 0.2rem 0.5rem;
		color: $dark-accent;
		&:active, &:visited {
			color: $dark-accent;
		}
		&.is-active {
			span {
				border-bottom: 2px solid $dark-accent;
				padding-bottom: 0.15rem;
			}
		}
	}

	.sub-nav {
		list-style: none;
		padding: 0.8rem 1rem;
		margin: 0;
		position: absolute;
		top: 100%;
		left: 0.2rem;
		min-width: 200px;
		box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
		background-color: #fff;
		border: 1px solid $light-grey;
		font-size: 0.95em;
		.nav-item {
			text-transform: capitalize;
			border: 1px solid transparent;
			border-radius: 0;
			padding: 0.1rem 0;
			line-height: 2em;
			color: $dark-accent;
			&:hover {
				border-bottom: 1px solid $dark-accent;
			}		
		}
	}
}

@media (max-width: 940px) {
	.header-space {
		height: 70px;
	}
	.nav {
		display: none;
	}
	.mobile-nav {
		display: block;
	}
	.mobile-nav-items {
		display: block;
		.nav-item-has-child {
			width: auto;	
		}
	}
}