@import '../../styles/colors';

.content {
	.hp-text {
		text-align: center;
		background-color: $light-grey;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		padding: 1.5rem 2rem;
		margin: -10% 0 1rem;
		font-family: "Roboto Condensed";
	    font-weight: 400;
	    font-size: 1.3em;
	}
	@media (max-width: 767px) {
		.hp-text {
			margin-top: -20%;
		}
	}

	@media (max-width: 399px) {
		.hp-text {
			margin: -30% 0 0;
			font-size: 1em;
			padding: 1rem 1.5rem;
		}
	}
}